import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import { RouterProvider } from '@tanstack/react-router';

import { AuthProvider } from './auth/AuthProvider';
import { useAuth } from './auth/useAuth';
import { router } from './router';

function RouterWithAuth() {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
}

export function App() {
  useEffect(() => {
    // Reload the page if there is a preload error on App first mount
    // https://vite.dev/guide/build#load-error-handling
    window.addEventListener('vite:preloadError', () => {
      window.location.reload();
    });
  }, []);

  return (
    <AuthProvider>
      <RouterWithAuth />
      <Toaster />
    </AuthProvider>
  );
}
